<template>
  <div class="pr-2 pl-2">
    <div class="d-flex" v-if="!editMode">
      <v-text-field
        style="cursor: pointer"
        class="managrx-input mr-2"
        label="Producto/Servicio"
        v-model="row.otherTonCostItemName"
        outlined
        dense
        readonly
        filled
        type="text"
        @click="goToTonCostPanel()"
      ></v-text-field>

      <v-text-field
        class="managrx-input mr-2"
        label="Precio Tonelada"
        v-model.number="row.priceTn"
        outlined
        dense
        readonly
        filled
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="(0-100)% App"
        v-model.number="percentApplicationAsInt"
        outlined
        append-icon="mdi-percent-outline"
        dense
        readonly
        filled
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="Subtotal"
        v-model.number="parsedItemCost"
        outlined
        dense
        readonly
        filled
        type="text"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="Fecha de uso"
        v-model="processedRow.dateUsed"
        outlined
        dense
        readonly
        filled
        type="date"
      ></v-text-field>

      <v-btn
        :depressed="true"
        v-if="!deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        v-if="deleteMode"
        :depressed="true"
        color="error"
        class="mr-2 white--text lighten-1"
        @click="confirmDeleteClick"
      >
        Confirmar borrado
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        depressed
        color="error  white--text"
        class="mr-2"
        @click="deleteClick"
        v-if="!deleteMode"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>
    <div class="d-flex" v-if="editMode">
      <v-select
        ref="costItemSelect"
        v-model="select"
        open-on-clear
        :items="costItems"
        return-object
        item-text="name"
        item-value="id"
        label="Elemento de costo"
        dense
        outlined
        class="managrx-input mr-2"
      ></v-select>

      <v-text-field
        class="managrx-input mr-2"
        label="Precio Tonelada"
        v-model.number="newRow.priceTn"
        outlined
        dense
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="(0-100)% App"
        v-model.number="newRow.percentApplication"
        append-icon="mdi-percent-outline"
        outlined
        dense
        type="number"
      ></v-text-field>
      <v-text-field
        class="managrx-input mr-2"
        label="SubTotal"
        v-model="computeSubtotal"
        outlined
        dense
        filled
        readonly
        type="text"
      ></v-text-field>

      <v-text-field
        class="managrx-input mr-2"
        label="Fecha de uso"
        v-model="newRow.dateUsed"
        outlined
        dense
        type="date"
      ></v-text-field>
      <v-btn
        :depressed="true"
        v-if="editMode"
        color="success white--text"
        class="mr-2"
        @click="saveClick"
      >
        <v-icon> mdi-floppy</v-icon>
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="!editMode"
        color="warning white--text"
        class="mr-2"
        @click="editClick"
      >
        <v-icon> mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="editMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atras
      </v-btn>
      <v-btn
        v-if="deleteMode"
        :depressed="true"
        color="error"
        class="mr-2 white--text lighten-1"
        @click="confirmDeleteClick"
      >
        Confirmar borrado
      </v-btn>
      <v-btn
        :depressed="true"
        v-if="deleteMode"
        color="warning white--text"
        class="mr-2"
        @click="backClick"
      >
        Atrás
      </v-btn>
      <v-btn
        depressed
        color="error  white--text"
        class="mr-2"
        @click="deleteClick"
        v-if="!deleteMode && !editMode"
      >
        <v-icon>mdi-trash-can</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.v-text-field {
  width: 400px;
}
.managrx-input {
  font-weight: 400 !important;
}
</style>

<script>
import { roundToX } from "../../managrx/utils";

import { mapGetters } from "vuex";
import { putRequest, deleteRequest } from "../../managrx/serverCall";
roundToX;
export default {
  name: "TonCostInput",
  props: {
    assignedHectares: Number,
    title: String,
    row: Object,
  },
  methods: {
    goToTonCostPanel() {
      let routeData = this.$router.resolve({
        name: "OtherTonCostItems",
      });
      window.open(routeData.href, "_blank");
    },
    async saveClick() {
      let payload = {
        otherTonCostItemId: this.select?.id,
        priceTn: this.newRow.priceTn,
        dateUsed: new Date(this.newRow.dateUsed).toISOString(),
        percentApplication: this.percentApplicationAsDecimal,
      };

      let endpoint =
        "/draft_assigned_other_ton_cost_item/" +
        this.newRow?.assignedOtherTonCostItemId;

      let response = await putRequest(endpoint, payload);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave", payload);

        this.backClick();
      }
    },
    editClick() {
      this.newRow = {
        ...this.row,
        dateUsed: this.row.dateUsed.split("T")[0],
        percentApplication: this.percentApplicationAsInt,
      };

      this.select = {
        id: this.newRow.otherTonCostItemId,
        name: this.newRow.otherTonCostItemName,
      };

      this.editMode = true;
    },
    backClick() {
      this.editMode = false;
      this.deleteMode = false;
    },
    deleteClick() {
      this.deleteMode = true;
    },
    async confirmDeleteClick() {
      let endpoint =
        "/draft_assigned_other_ton_cost_item/" +
        this.row.assignedOtherTonCostItemId;
      let response = await deleteRequest(endpoint);

      if (!response.success) {
        this.$store.dispatch("setNewNotification", response);
      } else {
        this.$emit("rowSave", {});
      }
      this.backClick();
    },
    resetNewRow() {},
  },
  computed: {
    ...mapGetters([
      // TODO: Do we need sliceInputCostPeriods here?
      "singleDraftActivitySlice",
      "otherTonCostItems",
    ]),
    percentApplicationAsInt() {
      return this.row?.percentApplication * 100;
    },
    percentApplicationAsDecimal() {
      return this.newRow.percentApplication / 100;
    },
    parsedItemCost() {
      return roundToX(this.row.itemCost, 2).toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });
    },
    fixedShareCroppingValue() {
      return this.singleDraftActivitySlice?.isFixedSharecroppingHandedOver
        ? 0
        : this.singleDraftActivitySlice?.fixedSharecroppingPercent;
    },
    variableShareCroppingValue() {
      return this.singleDraftActivitySlice?.isVariableSharecroppingHandedOver
        ? 0
        : this.singleDraftActivitySlice?.summary
            ?.variableSharecroppingTotalPercent;
    },
    producedTons() {
      return roundToX(
        this.singleDraftActivitySlice?.assignedHectares *
          this.singleDraftActivitySlice?.summary?.dynamicYield *
          (1 / 10),
        2
      );
    },
    ownTons() {
      return roundToX(
        this.producedTons *
          (1 - this.variableShareCroppingValue - this.fixedShareCroppingValue),
        2
      );
    },
    computeSubtotal() {
      return roundToX(
        this.ownTons * this.newRow.priceTn * this.percentApplicationAsDecimal,
        2
      ).toLocaleString("en-US", {
        timeZone: "America/Argentina/Buenos_Aires",
      });

    },
    processedRow() {
      return {
        dateUsed: this.row.dateUsed.split("T")[0],
      };
    },
  },
  async mounted() {
    this.costItems = [...this.otherTonCostItems];
  },
  data() {
    return {
      select: null,
      itemSubtotal: 0,
      editMode: false,
      deleteMode: false,
      selectedPeriod: null,
      newRow: {},
    };
  },
};
</script>
